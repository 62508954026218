import React, { useEffect, useState } from "react";
import { ThemeContext } from "../../layouts";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Article from "../../components/Article";
import Page from "../../components/Page";
import { navigate } from "gatsby";
import { checkSubscribedToNFL } from "../../utils/helpers";
import { isAuthenticated, getUserInfo, checkUserIsAdmin } from "../../utils/auth";
const SeasonLongQAPage = props => {
  const [isSubscribedNLF, setSubscribedNLF] = useState(null);

  useEffect(() => {
    if (!isAuthenticated()) {
      setSubscribedNLF(false);
    }

    const profile = typeof window !== "undefined" ? getUserInfo() : "";

    const checkSubscription = async () => {
      if (checkUserIsAdmin()) {
        setSubscribedNLF(true);
      } else {
        const res = await checkSubscribedToNFL(profile.email);
        if (res === 0) {
          setSubscribedNLF(false);
        } else {
          setSubscribedNLF(true);
        }
      }
    };
    checkSubscription();
  }, []);

  if (isSubscribedNLF === null) {
    return null;
  } else if (!isSubscribedNLF) {
    navigate("/membership");
    return null;
  } else {
    const {
      data: {
        seasonLongQa: {
          uid: slug,
          data: {
            content: { html: html },
            title: { text: title }
          }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = props;
    const page = {
      html,
      frontmatter: { title }
    };
    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Article theme={theme}>
              <Page page={page} theme={theme} />
            </Article>
          )}
        </ThemeContext.Consumer>
        <Seo facebook={facebook} />
      </React.Fragment>
    );
  }
};

export default SeasonLongQAPage;

export const query = graphql`
  query SeasonLongQa {
    seasonLongQa: prismicSeasonLongQa {
      uid
      data {
        content {
          html
        }
        title {
          text
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
